<template>
  <v-dialog v-model="dialog" width="850" persistent>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <div class="ma-2 pa-2 text-h6">
          1Life Software - Terms of Service Agreement
        </div>
      </v-footer>
      <v-card-text class="mt-4">
        <br />
        1. ACCEPTANCE OF TERMS - Thank you for choosing to use 1Life Workplace
        Safety Solutions technology product. These Terms and Conditions,
        hereinafter referred to as the "Terms" reflect your agreement with 1Life
        Workplace Safety Solutions Ltd. ("1Life") in respect of your use of and
        access to 1Life and the products and services provided therein. You
        acknowledge that you are accepting these terms and conditions as the
        administrator appointed by your employer ("Your Company") and for and on
        behalf of each employee of Your Company that is licensed to use the Site
        or the App, as applicable (the Site and the App together referred to as
        the "Technology").
        <br />
        <br />
        You hereby consent to the exchange of information and documents
        electronically over the Internet or by e-mail, if to you at the e-mail
        address(es) you provide to us in your account details and you also
        confirm that this electronic agreement is the equivalent of a written
        paper agreement between us.
        <br />
        <br />
        Your use of and access to the Technology is also subject to disclaimers,
        legal notices, click-through agreements, or other legal agreements,
        which are posted on the Site where applicable. The effective date of
        this Agreement is the date you accept or are deemed to accept this
        Agreement by clicking "I ACCEPT" or by using the Site. If you do not
        agree to any of these Terms, your access to or use of the Technology
        will be denied.
        <br />
        <br />

        2. DESCRIPTION OF SITE - 1Life created and maintains the Technology to
        assist you to (a) create and fill out digital forms; (b) read and sign
        off on digital resources; (c) set up digital resources for Your Company
        and its employees; and (d); store records and documents all with a view
        to assisting you in the management of workplace safety and health
        related risks. You are aware that this system is to improve your
        efficiency and is not a forum for obtaining legal advice and you are
        ultimately responsible for understanding and complying with the specific
        legislation and applicable laws within your jurisdiction/scope of work.
        <br />
        <br />
        3. PRODUCTS AND SERVICES - We provide access through the Technology to
        certain news feeds and documents, training programs, and other data
        created and maintained in our library (the "Public Library and Form
        Templates"). These products and services (the "Products and Services")
        are created and owned by 1Life and may be changed at any time and from
        time to time by 1Life. You acknowledge that the Products and Services
        are the exclusive property of 1Life and that the access to and use of
        the Products and Services is subject to these terms.
        <br />
        <br />
        4. USE - You agree to access and use the Technology and the Products and
        Services only for lawful purposes. You may not at any time use any of
        the Technology, Product or Services in any manner that violates the
        rights of 1Life or any third parties. You are solely responsible for
        knowledge of and adherence to any and all laws, statutes, standards,
        rules and regulations pertaining to (A) your use of the Site (B) the use
        of any Products and Services, including without limitation your use of
        the Public library and form templates, and (C) the means by which you
        connect to the Technology. You will not use the Technology to commit a
        criminal offense or to encourage conduct that would constitute a
        criminal offense or give rise to a civil liability, or otherwise violate
        any local, provincial, state, federal or international law, or interfere
        with the Technology or the ability of others to access the Technology.
        The material contained in the Technology is for information purposes
        only. Laws, regulations, and procedures are constantly changing, and
        examples given are for general guidelines only. It is your
        responsibility and the responsibility of Your Company to keep up to date
        and comply with current health and safety legislation and applicable
        standards and to ensure legal due diligence. It is your responsibility
        to identify, assess, control and communicate all risks and hazards and
        ensure the health and safety of all workers in the workplace so far as
        is reasonable and practicable and in material compliance with all
        applicable laws. 1Life is not responsible to ensure your compliance with
        applicable health and safety laws, and the Technology is not an
        exhaustive compilation of applicable health and safety laws.
        <br />
        <br />
        You agree not to use directly or indirectly the Technology to: <br />
        <br />

        <ol type="a">
          <li>
            publish, upload, post, email, transmit or otherwise make available
            any content that (a) you do not have the right to make available (b)
            is not owned by you or your Company, (c) is unlawful, harmful,
            vulgar, obscene, hateful, or racially, ethnically or otherwise
            objectionable; (d) infringes any intellectual property rights of any
            party including those of 1Life, (d) includes any unsolicited or
            unauthorized advertising, promotional materials, surveys, junk mail,
            spamming, chain letters, or any other form of solicitation,
            commercial or otherwise, or (e) contains a software virus, corrupted
            file, or any other computer file or software designed to interrupt,
            destroy, damage or limit the functionality of any computer hardware,
            software or other property;
          </li>
          <li>
            defame, harass, abuse, stalk, threaten or violate the legal rights
            of others such as rights of privacy and publicity;
          </li>
          <li>
            impersonate any person or entity, including, but not limited to, a
            1Life official, 1Life employee, or any other third party, or falsely
            state or otherwise misrepresent your affiliation with a person or
            entity;
          </li>
          <li>
            forge email headers or otherwise manipulate identifiers in order to
            disguise the origin of any content transmitted through the
            Technology;
          </li>
          <li>
            forge signatures or otherwise permit mischief to occur within your
            use of the Technology;
          </li>
          <li>
            download any file or content posted by another user that you know,
            or reasonably should know, should not be legally reproduced,
            displayed, performed and/or distributed in such manner;
          </li>
          <li>
            interfere with or disrupt the Technology, servers, or networks which
            support the Technology, or disobey any requirements, procedures,
            policies or regulations of networks connected to the Technology;
          </li>
          <li>
            violate any applicable local, state, national or international law
            and any regulations;
          </li>
          <li>violate the copyright of 1Life;</li>
          <li>
            collect or store personal information or data of other users without
            their consent; or
          </li>
          <li>
            permit access to the Technology by any former employee of Your
            Company.
          </li>
        </ol>
        <br />
        <br />
        You hereby acknowledge that the harm suffered by 1Life caused by any
        improper Uses of the Technology, Product of Services would not be
        compensable by monetary damages alone, and, accordingly, 1Life, in
        addition to other legal or equitable remedies shall be entitled to an
        injunction against such Improper Uses.
        <br />
        <br />
        5. THIRD PARTY ACCESS If you are accessing and/or using this Technology
        on behalf of Your Company or as a consultant or agent of a third party,
        you represent and warrant that you have the authority to act on behalf
        of and bind Your Company or the third party to these Terms and
        everywhere in these Terms that refers to you or your also includes Your
        Company and any third party to whom you or Your Company act as agent or
        consultant. Unless permitted by law or as otherwise expressly permitted
        in these Terms, you must not (nor may you authorize any third person
        to): (i) rent, lease, distribute, license, sublicense, sell, transfer,
        assign, distribute or otherwise provide access to the Technology to a
        third party; (iii) reproduce, modify, adapt, create derivative works of,
        the Technology; (iv) reverse engineer, disassemble, decompile, transfer,
        exchange or translate the Technology or otherwise seek to obtain or
        derive the source code or API; (iv) remove or tamper with any
        disclaimers or other legal notices; (v) combine the whole or any part of
        the Technology with any other software, data or material; (vi) store or
        use any part of the data you do not own in an archival database or other
        searchable database. You must promptly notify us in writing of any
        breach of these conditions of use
        <br />
        <br />
        6. CONTENT - Content posted to the Technology by you ("Your Content") is
        viewable by other users of the Technology with your consent. It is your
        responsibility to determine who is given access to Your Content (your
        "Network"). 1Life is not responsible for the dissemination of Your
        Content to users once authorized by you. Failure to access Your Content
        by a party authorized by You for a period of 14 days or more may result
        in the inactivation of that party's access to the Technology.
        <br />
        <br />
        Content made available on the Site by 1Life ("1Life Content") is
        intended to be available to all users and is not proprietary to you.
        1Life Content is subject to the copyright provisions contained in these
        Terms. 1Life Content is available to all users of the Site and you hold
        no exclusivity over any 1Life Content. To the extent the 1Life Content
        is modified by you, you represent that any such modifications, and the
        use of such modified 1Life Content, remains subject to the Terms
        contained herein. By accessing the technology, you confirm that all of
        the 1Life content is the exclusive property of 1Life and that you will
        not use it either directly or indirectly, except as permitted herein.
        <br />
        <br />
        7. LICENSE GRANT OF CONTENT - By transmitting, downloading or uploading
        Your Content or 1Life Content to or from the Technology, you grant 1Life
        and each authorized user of your Network a revocable, royalty-free,
        worldwide license to: access, reproduce, display, transmit, translate,
        and distribute Your Content in accordance with these Terms to such
        authorized users. You represent and warrant to 1Life that you have the
        right, title, and/or authority to grant such license to 1Life and the
        authorized users of the Technology.
        <br />
        <br />
        8. COLLECTION OF CONTENT - Regardless of how you obtain Your Content
        from users, you are responsible for securing all necessary permissions
        to reuse their information as Your Content.
        <br />
        <br />
        9. YOUR INFORMATION, PRIVACY, AND DATA PROTECTION - (A) Registration
        data and other information about you are subject to our Privacy Policy.
        1Life may access, preserve and disclose your personal information if
        required to do so by law or in order to: (i) comply with a legal
        process; (ii) respond to claims that any Content violates the rights of
        third parties or (iii) protect the rights, property or personal safety
        of 1Life, users of the Technology, and the public. (B) Your information
        may be stored and processed in Canada and by activating your account;
        you consent to the transfer of information into Canada. If you
        deactivate your account with 1Life, 1Life may purge or otherwise destroy
        any documentation uploaded after 12 months from date of deactivation of
        your account. If the laws of your jurisdiction require you to store
        information for a longer period, you are solely responsible for back-up
        of your information. (C) In registering for an account with 1Life in
        connection with the Technology, you will be assigned a username and
        password, which are for your own personal use only and are not
        transferrable. You are solely responsible (i) for maintaining the
        confidentiality of that password; (ii) to notify 1Life promptly in
        writing if you are required to deactivate your username due to actual or
        suspected breach of security regarding your username and/or password;
        (iii) for any losses incurred or resulting from failure to maintain the
        confidentiality of your password. Furthermore, you agree that you will
        notify 1Life promptly if you change your address or email so we can
        continue to contact you and send any notices required hereunder. If you
        fail to notify us promptly of a change, then any notice we send to your
        old address or email shall be deemed sufficient notice.
        <br />
        <br />
        10. INTELLECTUAL PROPERTY AND COPYRIGHTS â€“ As between you and us, we
        own, solely and exclusively, all rights, title and interest in and to
        the Technology, all 1Life Content (including but not limited to, for
        example, all code, programs, software, documentation, databases,
        systems, logos, libraries, audio, photographs, illustrations, graphics,
        other visuals, video, copy, text,) data and materials thereon, the look
        and feel, design and organization of the Site, and the compilation of
        the content, code, data and materials on the Site and within the App,
        including but not limited to any copyrights, trademark rights, patent
        rights, database rights, moral rights, sui generis rights and other
        intellectual property and proprietary rights therein. No intellectual
        property rights are licenses or transferred to you or Your Company in
        connection with these Terms or with respect to the Technology, including
        but not limited to the 1Life content. Your use of, or access to, the
        Technology does not grant to you ownership of any 1Life Content, code,
        data or materials you may access on or through the Technology. All
        trademarks, service marks, and logos used within the Technology are the
        trademarks, service marks, or logos of 1Life. Logos are trademarks of
        1Life and you may not use such names or logos without 1Life's prior
        written consent. Your misuse of the trademarks displayed on the Site or
        within the App or on or through any of the Site's services is strictly
        prohibited. Furthermore, you are not authorized to use any of our
        intellectual property except as is expressly allowed under these Terms.
        The reproduction, copying or redistribution of any of the 1Life content
        except(A) with the express written consent of 1Life or (B) for the
        exclusive use by Your Company in accordance with these terms is strictly
        prohibited.
        <br />
        <br />
        Subject to these Terms you may modify a template (for example a template
        policy, procedure, form or report) in the Technology for the purposes of
        developing customizations and additional features of a template. Any
        such modifications constitute "Your Modifications". You may use Your
        Modifications solely with respect to your own instances in support of
        your permitted use of the Technology, but you may not distribute Your
        Modifications to any third party. Notwithstanding anything in these
        Terms to the contrary, 1Life has no support, warranty, indemnification
        or other obligation or liability with respect to Your Modifications or
        their combination, interaction or use with our Technology. You must
        indemnify, defend and hold us harmless from and against any and all
        claims, costs, damages, losses, liabilities and expenses (including
        reasonable attorneys' fees and costs) arising out of or in connection
        with any claim brought against us by a third party relating to Your
        Modifications (including but not limited to any representations or
        warranties you make about Your Modifications or the Technology) or your
        breach of this Section. This indemnification obligation is subject to
        your receiving (i) prompt written notice of such claim (but in any event
        notice in sufficient time for you to respond without prejudice); (ii)
        the exclusive right to control and direct the investigation, defense, or
        settlement of such claim; and (iii) all reasonably necessary cooperation
        of 1Life at your expense.
        <br />
        <br />
        11. OUR LIABILITY - We reserve the sole right to either modify or
        discontinue the Technology, including any of the Technology's features
        or any Product or Service, at any time with or without notice to you. We
        will not be liable to you or any third party should we exercise such
        right. All new features shall also be subject to these Terms. We make no
        representation that the features, products and services available from
        this Technology from time to time satisfy the laws of your jurisdiction
        or comply with the requirements of government agencies that oversee your
        business activity.
        <br />
        <br />
        We assume no responsibility for Your Content or any modifications you
        make to 1Life Content while using the Technology, for which you are
        solely responsible.
        <br />
        <br />
        12. FREE SERVICES - We may offer certain Products, Services and
        Technology to you at no charge, including trial accounts, free accounts,
        and access to beta products (collectively referred to as Free Services).
        Your use of Free Services is subject to any additional terms that we
        specify and is only permitted for the period designated by us. We may
        terminate your right to use Free Services at any time and for any reason
        at our sole discretion, without liability to you. To the maximum extent
        permitted by applicable law, we disclaim all obligations or liabilities
        with respect to Free Services, including any support and maintenance,
        warranty, and indemnity obligations. At any time, 1Life reserves the
        right, at our sole discretion, to charge subscriptions and fees for
        services previously provided to you as Free Services. 1Life agrees to
        provide you notice of any such change and you agree to pay for any such
        subscriptions and fees in order to continue to access the Technology. If
        you do not agree to payment of subscriptions and fees, your sole remedy
        is to terminate your use of 1Life's Products, Services and Technology.
        <br />
        <br />
        Specifically, you are offered to access 1Life and a certain scope of
        functionality as determined by 1Life for a period of 15 days for an
        unlimited number of users in Your Company. You understand and agree that
        after 15 days, 1Life may restrict your free access to the Technology to
        5 users without any obligations or liability to you. Furthermore, 1Life
        reserves the right at its sole discretion to restrict the number of
        users to any number less than 5 users and to terminate all Free Services
        in their entirety at any time.
        <br />
        <br />
        If you or Your Company has not accessed Free Services for a period of 15
        days or more, 1Life reserves the right to terminate your account without
        notice.
        <br />
        <br />
        13. SUBSCRIPTION AND FEES - You agree to pay the subscription fees and
        any other charges incurred in connection with your account, username and
        password (including any applicable taxes) at the rates in effect when
        the charges were incurred.
        <br />
        <br />
        You agree that the applicable subscription fee applies to every active
        user who is accessing the Technology for any reason, for example but not
        limited to, to view, create, upload, complete or sign off on documents
        and forms. You agree not to turn off active users and assign their
        subscription to other users in Your Company to gain access to more
        Products, Services and Technology than you have paid for. If any such
        activity is discovered in your account, Your Company will be billed the
        full retail cost of the Products, Services and Technology used plus
        applicable taxes. In addition, you will be charged a $500 administration
        fee. Furthermore, 1Life reserves the right to revoke access to the
        Technology without warning.
        <br />
        <br />
        Subscription fees will be billed at the beginning of your subscription
        or any renewal. Unless we state in writing otherwise, all fees and
        charges are non-refundable. We may change the fees and charges then in
        effect, or add new fees or charges, by giving you notice in advance. All
        fees and pricing terms are confidential, and you agree not to disclose
        them to any third party. You are responsible for any fees or charges
        incurred to access the Technology through an Internet access provider or
        other third-party service.
        <br />
        <br />
        Your subscription will renew automatically, unless it is cancelled in
        accordance with this Section. Ninety (90) days written notice, provided
        by mail or email, is required to cancel your subscription. No refunds or
        credits will be issued for cancelled or terminated services.
        <br />
        <br />
        14. CHANGES AND NOTICES - 1Life reserves the right to make changes to
        these Terms at any time without prior notice to you. 1Life will notify
        you of any changes to these Terms when you visit the Site, or via email,
        or by providing you with information on such update which can be viewed
        via the Terms of Use link. 1Life may make changes to its products,
        service offerings and the Technology at any time without notice.
        <br />
        <br />
        15. TERMINATION AND ACCOUNTABILITY â€“ These Terms are effective until
        terminated by 1Life, with or without cause, in 1Life's sole and
        unfettered discretion. 1Life may terminate these Terms and revoke your
        access to the Technology without notice to you if you fail to comply
        with any of these Terms. Any such termination by 1Life shall be in
        addition to and without prejudice to any other rights and remedies
        available to 1Life, including injunction and other equitable remedies.
        The disclaimers, limitations on liability, ownership, termination,
        interpretation, your license to 1Life, your warranty and the indemnity
        provisions of these Terms shall survive the termination or expiry of
        these Terms.
        <br />
        <br />
        In the event you are in material breach of these Terms, 1Life may, at
        its sole discretion, refuse you any current or future use of the
        Technology. 1Life may remove any of Your Content posted on the Site at
        1Life's sole discretion. 1Life shall not be liable to you or any third
        party for any termination or change to the Technology. If you send or
        upload Content that is confidential or proprietary of a third party
        without that third party's permission, or, if you transmit or upload
        Content that is intended to infect, corrupt or otherwise disrupt the
        operation of the Technology or any other user's computer system, 1Life
        may report you to the relevant authorities to ensure you are held
        accountable to the fullest extent of applicable laws.
        <br />
        <br />
        You represent that you have all consents required from third parties to
        use this Technology, including without limitation the consent of any
        government agent or government agency you name as a contact within this
        Technology.
        <br />
        <br />
        16. DISCLAIMER OF WARRANTIES - Although 1Life takes steps to keep its
        website and mobile applications and servers secure, there is no
        guarantee that information and transactions on this Technology or on the
        internet will be maintained confidential and secure. The use of this
        Technology and the content is at Your own risk and 1Life assumes no
        liability or responsibility pertaining to the content, Your use of the
        content, the Technology or the receipt, storage, transmission or other
        use of Your information.
        <br />
        <br />
        The Technology, including, without limitation, all services, features,
        content, functions and materials provided through the Site, are provided
        "as is," "as available," without warranty of any kind, either express or
        implied, including, without limitation, any warranty for information,
        data, data processing services, uptime or uninterrupted access, any
        warranties concerning the availability, playability, display, ability,
        accuracy, precision, correctness, thoroughness, completeness,
        usefulness, or content of information, and any warranties of title,
        non-infringement, merchantability or fitness for a particular purpose,
        and we hereby disclaim any and all such warranties, express and implied.
        We do not warrant that the Technology or the products, services,
        content, functions or materials provided through the Technology will be
        timely, secure, un-interrupted or error free, or that defects will be
        corrected. We make no warranty that the Technology or the products or
        services will meet users' requirements. No advice, results or
        information, whether oral or written, obtained by you from us or through
        the Technology shall create any warranty not expressly made herein. We
        also assume no responsibility, and shall not be liable for, any damages
        to, or viruses that may infect, your equipment on account of Your access
        to, use of, or browsing in the Technology or Your downloading of any
        materials, data, text, images, video content, or audio content from the
        Technology. If You are dissatisfied with the Site, your sole remedy is
        to discontinue using the Technology.
        <br />
        <br />
        We try to ensure that the information posted in the public library and
        form templates is correct and up-to-date. We reserve the right to change
        or make corrections to any of the information provided in the public
        library and form templates and form templates at any time and without
        any prior warning. We neither endorse nor are responsible for the
        accuracy or reliability of any opinion, advice or statement contained in
        the Technology, nor for any offensive, defamatory, obscene, indecent,
        unlawful or infringing posting made thereon by anyone other than our
        authorized employee spokespersons while acting in their official
        capacities (including, without limitation, other users of the
        Technology). It is your responsibility to evaluate the accuracy,
        completeness or usefulness of any information, opinion, advice or other
        content available through the Technology. Please seek the advice of
        professionals, as appropriate, regarding the evaluation of any specific
        information, opinion, advice or other content.
        <br />
        <br />
        The Technology may contain links to other sites. Such other sites will
        be governed by their own terms and conditions and it is your
        responsibility to review and comply with same. 1Life does not control
        any such third party sites, nor is it a publisher or disseminator of the
        material contained on those other sites and does not seek to monitor or
        control those sites. A link to another site should not be construed to
        mean that 1Life endorses, is associated or affiliated with the operators
        of such sites. In accessing these sites, you are doing so at your own
        risk.
        <br />
        <br />
        You should use your best judgment in evaluating and applying the 1Life
        Content, or any information contained, or opinions expressed on the
        Technology. You are solely responsible for use of the 1Life Content,
        including without limitation, the use of the information as the basis
        for any conclusions.
        <br />
        <br />
        17. INDEMNITY - You agree to indemnify, defend and hold harmless 1Life,
        its Directors, Officers, employees and agents against all actions,
        proceedings, costs, claims, damages, demands, liabilities and expenses
        whatsoever (including legal and other fees and disbursements) sustained,
        incurred, arising from, or paid by 1Life, directly or indirectly, in
        respect of your use or misuse of the content or the Technology,
        infringement claims resulting from Your use, a breach of these terms and
        conditions of use or any other policy of 1Life, Your use or accessing of
        any internet site linked to or from the Technology.
        <br />
        <br />
        18. LIMITATION OF LIABILITY - UNDER NO CIRCUMSTANCES SHALL 1LIFE, ITS
        DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS OR OTHERWISE, BE
        LIABLE FOR ANY INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES UNDER OR
        ARISING FROM USE OF THE CONTENT, THE TECHNOLOGY OR ANY INTERNET SITE
        LINKED TO OR FROM THE TECHNOLOGY, WHETHER FOR BREACH OF CONTRACT,
        TORTIOUS BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION,
        INCLUDING WITHOUT LIMITATION, ANY LIABILITY FOR DAMAGES CAUSED OR
        ALLEGEDLY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
        INTERRUPTION, ELECTRICAL SURGE/DAMAGE/INTERFERENCE, DELETION, DEFECT,
        DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATIONS LINE
        FAILURE, BREAKDOWN OF EQUIPMENT, SOFTWARE ERROR, INFRINGEMENT,
        UNAUTHORIZED ACCESS TO, OR THEFT, DESTRUCTION, ALTERATION, OR USE OF,
        RECORDS OR PERSONAL INFORMATION.
        <br />
        <br />
        UNDER NO CIRCUMSTANCES SHALL 1LIFE BE LIABLE TO YOU OR ANY OTHER THIRD
        PARTY FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON THE
        CONTENTS AVAILABLE THROUGH THE PUBLIC LIBRARY AND FORM TEMPLATES AND
        FORM TEMPLATES PROVIDED BY 1LIFE OR THE CONTENT CONTAINED WITHIN ANY
        INTERNET SITE LINKED TO OR FROM THE TECHNOLOGY. THE CONTENT WITHIN THE
        TECHNOLOGY AND THE CONTENT WITHIN OTHER INTERNET SITES LINKED TO OR FROM
        THE TECHNOLOGY MAY INCLUDE TECHNICAL OR OTHER INACCURACIES OR
        TYPOGRAPHICAL ERRORS. CHANGES IN NEW VERSIONS OF THE TECHNOLOGY ARE
        COVERED BY THE TERMS OF THIS AGREEMENT. 1LIFE MAY MAKE IMPROVEMENTS OR
        OTHER CHANGES IN THE 1LIFE CONTENT AND THESE TERMS AND CONDITIONS AT ANY
        TIME AND FROM TIME TO TIME. IT IS YOUR OBLIGATION TO REVIEW THE
        TECHNOLOGY AND THESE TERMS AND CONDITIONS AS THEY ARE BINDING ONCE
        POSTED. WITH RESPECT TO ANY REFERENCES TO SPECIFIC LEGISLATION, YOU
        ACKNOWLEDGE THAT LEGISLATION CHANGES FROM TIME TO TIME AND THAT YOU MUST
        CONSULT THE ACTUAL CURRENT LEGISLATION IN YOUR JURISDICTION FOR ALL
        PURPOSES RELATING TO THE INTERPRETATION AND APPLICATION OF ANY STATUTES,
        LEGISLATION OR OTHER LAWS OF APPLICATION. THIS TECHNOLOGY IS NOT
        PROVIDING ANY ADVICE ON THE INTERPRETATION OF SUCH LEGISLATION.
        <br />
        <br />
        19. APPLICABLE LAW - 1Life and the Technology (excluding linked websites
        or content) are physically located within Canada. All services are
        deemed to be offered from Manitoba, Canada. 1Life makes no
        representation that the Technology is appropriate or available for use
        in all locations and jurisdictions. If you choose to access the
        Technology, you do so at your own risk and are solely responsible for
        compliance with the laws of your jurisdiction. The law of the Province
        of Manitoba, excluding its conflict of law principals, shall govern all
        issues and matters relating to or arising from your use of the
        Technology and the parties attorn to the exclusive jurisdiction of the
        Courts of the Province of Manitoba with respect to any dispute relating
        to the subject matter of this Agreement.
        <br />
        <br />
        20. INTERPRETATION - The division of these Terms into sections and the
        insertion of headings are for convenience of reference only and shall
        not affect the construction or interpretation of these Terms. In these
        Terms, words importing the singular number include the plural and vice
        versa.
        <br />
        <br />
        21. SURVIVAL - Your confidentiality obligations hereunder survive
        termination of your access rights. Upon any termination or expiry of
        your account, or upon 1Life's written request, you must cease use of the
        Site and any materials contained therein, whether forming part of 1Life
        Content or copyrighted materials, and return or destroy all copyrighted
        materials in your possession or control.
        <br />
        <br />
        22. WAIVER AND SEVERABILITY - The failure of 1Life to exercise or
        enforce any right or provision of these Terms shall not constitute a
        waiver of such right or provision. To the extent that any provision in
        these Terms shall be found to be unenforceable, such provision shall be
        modified in such a manner so as to make these Terms as modified, legal
        and enforceable under applicable laws and the balance of the provisions
        of these Terms shall not be affected thereby.
        <br />
        <br />
        23. ENTIRE AGREEMENT AND REVISIONS -These Terms constitute the entire
        agreement between You and 1Life concerning the subject matter hereof and
        supersede any prior understanding or agreement, whether oral or written.
        1Life may revise these Terms or any other policy at any time and from
        time to time, and such revision shall be effective upon posting notice
        of such revision on the Site and within the App. You agree to review
        these Terms on a regular basis to ensure that you are aware of all
        applicable terms and conditions, including as amended from time to time.
        You signify that you agree to be bound by such changes by Your use of
        the Technology after changes are made to the Terms. If any such revision
        is unacceptable to you, you must discontinue Your use of the Technology.
        Your continued access and use of the Technology following notice of any
        such revision shall conclusively be deemed acceptance of all such
        revisions.
        <br />
        <br />
        24. MISCELLANEOUS - If any of the Terms or any other policies of 1Life
        are held to be invalid or unenforceable, that portion shall be construed
        in accordance with applicable law as nearly as possible to reflect the
        original intention of the parties and the remaining portions will
        continue in full force and effect. The failure of 1Life to insist upon
        or enforce strict performance of any of the Terms shall not be construed
        as a waiver of any right to do so at any other time. These Terms shall
        endure to the benefit of 1Life and be binding upon you, your respective
        heirs, successors and permitted assigns. You acknowledge that you have
        read and understood these Terms prior to acceptance and confirm that you
        have the authority and capacity to enter into this Agreement. Please
        ensure that you print or otherwise save a copy of these Terms.
        <br />
        <br />
        25. QUESTIONS OR ADDITIONAL INFORMATION: If you have questions regarding
        this Agreement or wish to obtain additional information, please send an
        e-mail to info@1lifewss.com.
        <br />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="cancelTerms" :disabled="isLoading">
          cancel
        </v-btn>
        <v-btn
          class="msaBlue white--text"
          :disabled="isLoading"
          @click="acceptTerms"
        >
          i accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
export default {
  name: 'TermsOfServiceDialog',
  data() {
    return {
      dialog: false,
      user: null,
      token: null,
      isLoading: false,
    };
  },
  methods: {
    show(user, token) {
      this.user = user;
      this.token = token;
      this.dialog = true;
    },
    close() {
      this.user = null;
      this.token = null;
      this.dialog = false;
    },
    cancelTerms() {
      this.isLoading = true;
      const logout = () => {
        const params = {
          jwtToken: this.token,
        };
        const promise = axios.create({
          baseURL: process.env.VUE_APP_BASE_URL,
          useWithCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
        });
        promise
          .post('logout?format=json', params, {
            withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
          })
          .then(() => {
            this.$store.commit('invalidateStore');
            if (this.$route.name !== 'UserLogin') {
              this.$router.push({ name: 'UserLogin' });
            } else {
              this.$router.go(0);
            }
          });
      };
      this.$root.showMessage(
        'Are you sure?',
        'To use the application, you must accept the Terms and Conditions. Are you sure you want to cancel?',
        () => logout(),
        () => (this.isLoading = false),
        'Yes, cancel',
        'NO',
        true,
      );
    },
    acceptTerms() {
      this.isLoading = true;
      this.$store.commit('updateUser', this.user);
      this.$store.commit('updateJwtToken', this.token);
      this.$axios
        .post('accept-terms-of-service?format=json', {
          loaderText: 'loading...',
        })
        .then(() => {
          let user = { ...this.user, ...{ acceptedTermsOfService: 1 } };
          this.$emit('accepted', { user: user, token: this.token });
          this.dialog = false;
        });
    },
  },
};
</script>
